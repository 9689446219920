@font-face {
	font-family: 'Summit';
	src: url('../../fonts/High\ Summit.otf');
	src: url('../../fonts/High\ Summit.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Grotesk';
	src: url('../../fonts/HKGrotesk-Regular.otf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Argentum Sans';
	src: url('../../fonts/ArgentumSans-Regular.ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

$Grotesk: 'Grotesk';
$Gabarito: 'Gabarito', sans-serif;
$NewDay: 'Dawning of a New Day', cursive;

$Archivo: 'Archivo Black', sans-serif;
$Julius: 'Julius Sans One', sans-serif;
$Poppins: 'Poppins', sans-serif;
$Summit: 'Summit';
$OpenSans: 'Open Sans', sans-serif;
$Yeseva: 'Yeseva One', cursive;
$Argentum: 'Argentum Sans', sans-serif;

@use '../assets' as a;

.contact-bg {
	background-color: a.$black;
}

.contact-form {
	font-size: 1.6rem;
	text-align: left;
	margin: 0 auto;
	max-width: 80rem;

	@media only screen and (min-width: 1200px) {
		padding: 2rem 0 4rem;

		textarea {
			min-height: 14rem;
		}
	}

	.errors {
		color: rgb(171, 40, 40);
		font-family: a.$Grotesk;
		font-size: 1.4rem;
	}

	div {
		display: flex;
		flex-direction: column;
	}

	label {
		padding: 2rem 0 0.5rem 0;
		color: a.$white;
		font-family: a.$Grotesk;
	}

	textarea {
		min-height: 15rem;
	}

	input:not([type='submit']),
	textarea {
		border: 1px solid a.$white;
		border-radius: 12px;
		background: none;
		padding: 0.8rem;
		color: a.$white;
		outline: none;
		-webkit-transition: 0.5s;
		transition: 0.5s;
		font-family: a.$Grotesk;
		font-size: 1.6rem;
	}

	input[type='submit'] {
		background-color: a.$cta;
		color: a.$black;
		transition: all 0.3s ease-out;
	}

	input[type='submit']:hover {
		background-color: transparent;
		outline: 1px solid a.$cta;
		color: a.$cta;
		cursor: pointer;
	}

	@media only screen and (min-width: 768px) {
		.contact-bg {
			padding: 0rem 1.6rem;
		}

		input[type='submit'] {
			width: 30%;
			margin: 3rem auto 0;
		}

		.success {
			padding: 8rem 0;
		}
	}
}

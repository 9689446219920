@use '../assets' as a;

.start-project-content {
	background-color: a.$white;

	.notice {
		padding: 8rem 1.6rem;
		height: 100vh;
		margin: 0 auto;
	}
}

@use './colors' as c;
@use './fonts' as f;

:root,
html {
	font-size: 62.5%;
	/* 10/16 = 0.625 * 100 = 62.5%, so we're working in a base of 10 for font size.*/
	scroll-behavior: smooth;
	user-select: none;
}

h1,
h2,
h3 {
	font-family: f.$Gabarito;
	color: c.$white;
	line-height: 1.5;
}

h1 {
	font-size: clamp(4rem, 2.24rem + 5.5vw, 12.8rem);
}

h2 {
	font-size: clamp(3.2rem, 2.88rem + 1vw, 4.8rem);
}

h3 {
	font-size: 2.4rem;
}

p,
ul,
ol,
li,
a {
	font-size: 1.6rem;
	line-height: 1.5;
	font-family: f.$Grotesk;
	color: c.$white;
	margin-bottom: 1rem;
}

.tagline {
	font-size: 3.2rem;
	font-family: f.$NewDay;
	font-weight: 400;
	font-style: normal;
}

// .header h2 {
// 	font-size: clamp(4rem, 2.72rem + 4vw, 10.4rem);
// 	line-height: 1;
// }

@keyframes wiggle {
	0% {
		transform: rotate(-5deg);
	}
	50% {
		transform: rotate(5deg);
	}
	100% {
		transform: rotate(-5deg);
	}
}

.wiggle-div {
	animation: wiggle 0.5s infinite;
}

.stop-wiggle {
	animation: none; /* Stop the wiggle animation */
}

.container {
	max-width: 120rem;
	margin: 0 auto;
	text-align: center;
	padding: 4rem 1.6rem;

	@media only screen and (min-width: 1200px) {
		padding: 0;
	}
}

.custom-icon g {
	color: rgba(29, 99, 68, 0.1);
}

/* changing text in cta on home page */

.words-1 h2 {
	position: absolute;
	opacity: 0;
	overflow: hidden;
	color: c.$cta;
	-webkit-animation: rotateWord 18s linear infinite 0s;
	-moz-animation: rotateWord 18s linear infinite 0s;
	-o-animation: rotateWord 18s linear infinite 0s;
	-ms-animation: rotateWord 18s linear infinite 0s;
	animation: rotateWord 18s linear infinite 0s;
}
.words-1 h2:nth-child(2) {
	-webkit-animation-delay: 3s;
	-moz-animation-delay: 3s;
	-o-animation-delay: 3s;
	-ms-animation-delay: 3s;
	animation-delay: 3s;
}
.words-1 h2:nth-child(3) {
	-webkit-animation-delay: 6s;
	-moz-animation-delay: 6s;
	-o-animation-delay: 6s;
	-ms-animation-delay: 6s;
	animation-delay: 6s;
}
.words-1 h2:nth-child(4) {
	-webkit-animation-delay: 9s;
	-moz-animation-delay: 9s;
	-o-animation-delay: 9s;
	-ms-animation-delay: 9s;
	animation-delay: 9s;
}
.words-1 h2:nth-child(5) {
	-webkit-animation-delay: 12s;
	-moz-animation-delay: 12s;
	-o-animation-delay: 12s;
	-ms-animation-delay: 12s;
	animation-delay: 12s;
}
.words-1 h2:nth-child(6) {
	-webkit-animation-delay: 15s;
	-moz-animation-delay: 15s;
	-o-animation-delay: 15s;
	-ms-animation-delay: 15s;
	animation-delay: 15s;
}
@-webkit-keyframes rotateWord {
	0% {
		opacity: 0;
	}
	2% {
		opacity: 0;
		-webkit-transform: translateY(-30px);
	}
	5% {
		opacity: 1;
		-webkit-transform: translateY(0px);
	}
	17% {
		opacity: 1;
		-webkit-transform: translateY(0px);
	}
	20% {
		opacity: 0;
		-webkit-transform: translateY(30px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@-moz-keyframes rotateWord {
	0% {
		opacity: 0;
	}
	2% {
		opacity: 0;
		-moz-transform: translateY(-30px);
	}
	5% {
		opacity: 1;
		-moz-transform: translateY(0px);
	}
	17% {
		opacity: 1;
		-moz-transform: translateY(0px);
	}
	20% {
		opacity: 0;
		-moz-transform: translateY(30px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@-o-keyframes rotateWord {
	0% {
		opacity: 0;
	}
	2% {
		opacity: 0;
		-o-transform: translateY(-30px);
	}
	5% {
		opacity: 1;
		-o-transform: translateY(0px);
	}
	17% {
		opacity: 1;
		-o-transform: translateY(0px);
	}
	20% {
		opacity: 0;
		-o-transform: translateY(30px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@-ms-keyframes rotateWord {
	0% {
		opacity: 0;
	}
	2% {
		opacity: 0;
		-ms-transform: translateY(-30px);
	}
	5% {
		opacity: 1;
		-ms-transform: translateY(0px);
	}
	17% {
		opacity: 1;
		-ms-transform: translateY(0px);
	}
	20% {
		opacity: 0;
		-ms-transform: translateY(30px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes rotateWord {
	0% {
		opacity: 0;
	}
	2% {
		opacity: 0;
		transform: translateY(-30px);
	}
	5% {
		opacity: 1;
		transform: translateY(0px);
	}
	17% {
		opacity: 1;
		transform: translateY(0px);
	}
	20% {
		opacity: 0;
		transform: translateY(30px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.custom-corner-border {
	--s: 50px; /* the size on the corner */
	--t: 2px; /* the thickness of the border */
	--g: 10px; /* the gap between the border and image */

	padding: calc(var(--g) + var(--t));
	outline: var(--t) solid #214234; /* the color here */
	outline-offset: calc(-1 * var(--t));
	mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 /
			calc(100% - var(--s)) calc(100% - var(--s)),
		linear-gradient(#000 0 0) content-box;
	border-radius: 20px;
}

.custom-glowing-circle {
	background-color: #115e3b; /* Forest Green */
	border-radius: 50%;
	filter: blur(30px); /* Blurs the entire circle */
	box-shadow: 0 0 100px 20px rgba(34, 139, 92, 0.7);
	-webkit-box-shadow: 0 0 100px 20px rgba(34, 139, 92, 0.7);
	-moz-box-shadow: 0 0 100px 20px rgba(34, 139, 92, 0.7);
	opacity: 0.2;
}

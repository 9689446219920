@use '../assets' as a;

footer {
	.footer-container {
		margin: 0 auto !important;
		max-width: 140rem;
	}

	.footer_top {
		@media only screen and (min-width: 768px) {
			display: flex;
			align-items: baseline;
		}
		.top_one {
			flex: 1;

			.logo {
				margin-bottom: 1rem;
				text-align: center;

				@media only screen and (min-width: 768px) {
					text-align: left;
					margin-bottom: 0;
				}

				a {
					line-height: 1;
					font-family: a.$Gabarito;
					color: a.$white;
					font-size: clamp(2.4rem, 2.24rem + 0.5vw, 3.2rem);
				}
			}
		}

		.top_three {
			flex: 1;
			ul {
				display: flex;
				gap: 2rem;
				justify-content: center;

				@media only screen and (min-width: 768px) {
					justify-content: flex-end;
					gap: 3rem;
				}

				a {
					font-size: 1.6rem;
					color: a.$white;
					font-family: a.$Grotesk;

					&:hover {
						color: a.$white;
						transition: 0.2s;
					}
				}
			}
		}
	}

	.hl2 {
		width: 100%;
		border-bottom: 1px solid a.$white;
		margin: 2rem auto;

		@media only screen and (min-width: 768px) {
			margin: 1rem auto;
		}
	}

	.footer_bottom {
		@media only screen and (min-width: 768px) {
			display: flex;
			align-items: flex-start;
		}

		.bottom_one {
			max-width: 60rem;
			margin: 0 auto;
			flex: 1;
			text-align: center;

			@media only screen and (min-width: 768px) {
				text-align: left;
				margin: 0 0 2rem;
			}

			p {
				font-size: 1.4rem;
			}
		}

		.bottom_two {
			margin-top: 2rem;
			flex: 1;
			text-align: center;

			p {
				font-size: 1.4rem;
			}
		}

		.bottom_three {
			padding-bottom: 2rem;
			flex: 1;
			.socials {
				justify-content: center;
				@media only screen and (min-width: 768px) {
					justify-content: flex-end;
				}
			}
		}
	}
}

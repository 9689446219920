@use '../assets' as a;

.navbar {
	&-container {
		font-family: a.$Grotesk;
		font-size: 1.8rem;
		max-width: 140rem;
	}

	.logo {
		max-width: 10rem;
		text-align: left;
		margin-left: 1.6rem;

		@media only screen and (min-width: 768px) {
			max-width: 16rem;
		}

		a {
			line-height: 1;
			font-family: a.$Gabarito;
			color: a.$white;
			font-size: clamp(2.4rem, 2.24rem + 0.5vw, 3.2rem);
		}
	}

	.is-active {
		top: 0;
		right: 0;
		transition-duration: 0.3s;
	}

	.hamburger {
		cursor: pointer;
		appearance: none;
		outline: none;
		z-index: 100;
		padding: 2rem;
	}

	.hamburger span {
		display: block;
		width: 3rem;
		height: 0.4rem;
		margin-bottom: 0.5rem;
		position: relative;
		border-radius: 0.6rem;
		transform-origin: 0 0;
		transition: 0.4s;
		background-color: a.$white;
	}

	.hamburger.is-active span:nth-child(1) {
		transform: translate(0rem, -0.15rem) rotate(45deg);
		background-color: a.$white;
	}

	.hamburger.is-active span:nth-child(2) {
		opacity: 0;
		transform: translate(1.5rem);
	}

	.hamburger.is-active span:nth-child(3) {
		transform: translate(-0.3rem, 0.2rem) rotate(-45deg);
		background-color: a.$white;
	}

	.nav-menu {
		position: fixed;
		display: block;
		top: -150%;
		left: 0;
		width: 100%;
		z-index: 2;
		padding-top: 35%;
		transition: 1s;
		background-color: a.$black;
		height: 100vh;
	}

	.nav-item {
		a {
			color: a.$white;
			padding: 3rem;
			display: block;
			text-align: center;
		}

		&.socials {
			display: flex;
			justify-content: center;

			@media only screen and (min-width: 768px) {
				display: none;
			}

			a {
				padding: 1rem;
			}
		}
	}

	.active {
		top: 0;
	}

	// Desktop Navigation Changes
	@media only screen and (min-width: 768px) {
		.nav-menu {
			position: relative;
			padding-top: 0;
			display: flex;
			background: none;
			transition: 0s;
			height: auto;
		}

		.nav-item .nav-links {
			color: a.$white;
			transition: color 0.3s;
			position: relative;
			padding: 2rem 3rem;
			font-size: 1.6rem !important;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 0.1rem;
				background-color: a.$white; /* Underline color */
				transition: width 0.3s; /* Transition underline width */
			}

			&:hover {
				&::before {
					width: 100%;
				}
			}
		}

		.hamburger {
			display: none;
			padding: 0;
		}

		.hamburger span {
			display: none;
		}
	}
}

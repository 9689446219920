@use '../assets' as a;

.btn {
	padding: 1rem 3rem;
	font-family: a.$Grotesk;
	font-size: 1.6rem;
	outline: none;
	border: none;
	cursor: pointer;
	margin-top: 3rem;
	border-radius: 16px;
}

.btn--primary {
	background-color: a.$white;
	color: a.$white;
	transition: all 0.3s ease-out;
}

.btn--primary:hover {
	background-color: transparent;
	outline: 1px solid a.$white;
	color: a.$white;
}

.btn--secondary {
	background-color: a.$cta;
	color: a.$black;
	transition: all 0.3s ease-out;
}

.btn--secondary:hover {
	background-color: transparent;
	outline: 1px solid a.$cta;
	color: a.$cta;
}

.btn--custom {
	display: flex;
	align-items: center;
	gap: 1rem;
}

@media only screen and (min-width: 768px) {
	.btn {
		font-size: 1.8rem;
		width: 25rem;
	}

	.btns a {
		width: 100%;
	}
}

//colors
$black: #1a1a1a;
$cta: #32a06b;
$white: #f1f1f1;
$green: #415441;
$extradark: #131313;

$ctaLight: #e9d0d3;

//hover states

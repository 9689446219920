@use '../assets' as a;

.accordion {
	background-color: a.$black;

	h3 {
		button {
			background-color: a.$extradark;
			width: 100%;
			text-align: left;
			padding: 1rem 2rem;
			margin: 0.5rem 0;
			border-radius: 12px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.szh-accordion__item-content {
		padding: 1rem;
		margin-top: -2.5rem;
		background-color: a.$extradark;
		border-radius: 0 0 12px 12px;
		margin-bottom: 1rem;
	}

	p,
	li {
		text-align: left;
		margin: 1rem;

		a {
			color: a.$cta;
			transition: 0.2s;

			&:hover {
				transition: 0.2s;
				background-color: a.$black;
			}
		}
	}
}

@use '../assets' as a;

.home-content {
	.highlights {
		max-width: 120rem;

		h2 {
			margin-bottom: 1rem;
			@media only screen and (min-width: 768px) {
				text-align: left;
			}
		}

		.highlights-list {
			display: flex;
			flex-direction: column;
			gap: 4rem;
			padding: 0 4rem;

			@media only screen and (min-width: 768px) {
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				margin: 4rem 0 3rem;
			}
		}

		.project {
			position: relative;
			display: flex;

			//hover effect over each project
			&:hover {
				.img-container {
					filter: brightness(0.5);

					+ .title {
						display: block;
					}
				}
			}

			@media only screen and (min-width: 768px) {
				width: 44%;
				display: flex;
				position: relative;
			}

			.vertical-text {
				position: absolute;
				top: 2rem;
				left: 0;
				font-family: a.$Grotesk;
				font-size: 1.4rem;
				letter-spacing: -0.015em;
				color: a.$white;
				-webkit-transform: rotate(-180deg);
				transform: rotate(-180deg);
				-webkit-writing-mode: tb-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: tb-rl;
				white-space: nowrap;
			}

			.title {
				background-color: a.$black;
				position: absolute;
				top: 70%;
				padding: 0.5rem 1rem 0.5rem 4rem;
				transition: 0.2s;

				@media only screen and (min-width: 768px) {
					padding: 0.5rem 1rem 0.5rem 6rem;
				}

				@media only screen and (min-width: 1024px) {
					//hide it in desktop for the hover effect
					display: none;
				}
			}

			.img-container {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				transition: 0.2s;

				img {
					height: 40rem;
					width: 92%;
					object-fit: cover;
					object-position: center;
					align-self: flex-end;

					@media only screen and (min-width: 1024px) {
						height: 50rem;
					}
				}
			}
		}
	}
}

@use '../assets/' as a;

.portfolio-view {
	.container {
		padding: 4rem 1.6rem;
		max-width: 100rem;
		margin: 0 auto;

		@media only screen and (min-width: 768px) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 1rem;
		}

		.portfolio-view_left {
			flex: 1;
		}

		.portfolio-view_right {
			flex: 1;

			img {
				@media only screen and (min-width: 768px) {
					max-height: 100rem;
					width: 100%;
					object-fit: contain;
				}
			}
		}

		.portfolio-view_bottom {
			width: 100%;
		}

		.specs {
			padding-bottom: 4rem;
			color: a.$white;
			flex: 1;

			.project-type {
				font-size: 1.6rem;
			}

			.project-title {
				font-size: 4rem;
			}

			.palette {
				display: flex;
				justify-content: space-around;
				width: 100%;
				margin: 2rem auto 0 auto;
				gap: 1rem;

				span {
					border-radius: 50%;
					height: 6rem;
					width: 6rem;
					box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;

					@media only screen and (min-width: 768px) {
						height: 8rem;
						width: 8rem;
					}
				}
			}

			.fonts {
				margin: 4rem auto 2rem;
				width: 100%;

				.font-title {
					font-size: 3.2rem;
					font-weight: bold;
				}

				.font-body {
					font-size: 1.6rem;
				}
			}
		}

		.project-desc {
			p {
				font-size: 1.6rem;
				text-align: left;
				max-width: 76rem;
			}

			.project-links {
				margin-top: 2rem;
				text-align: center;

				a {
					color: a.$white;
					font-size: 1.6rem;
					font-family: a.$Grotesk;
					transition: 0.2s;
					display: flex;
					align-items: center;
					gap: 0.5rem;
					justify-content: center;
					margin-bottom: 2rem;

					@media only screen and (min-width: 768px) {
						justify-content: left;
					}

					&:hover {
						color: a.$white;
					}
				}
			}
		}
	}
}

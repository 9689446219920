@use '../assets' as a;

.socials {
	&.hor {
		display: flex;
		gap: 0.5rem;
	}

	&.white {
		a {
			color: a.$white;
		}
	}

	a {
		z-index: 100;
		color: a.$white;

		svg {
			margin-top: 2rem;
			height: 2rem;
		}
	}

	svg:hover {
		transition: 0.2s;
	}
}

@use '../assets' as a;

.portfolio {
	&-header {
		max-width: 140rem;
	}

	&-list,
	.case-list {
		padding: 0rem 1.6rem 4rem;
		max-width: 140rem;
		margin: 0 auto;
		display: flex;
		flex-direction: column;

		@media only screen and (min-width: 768px) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
		}

		.project,
		.case {
			padding: 4rem 0rem;
			border-bottom: 1px solid rgb(223, 223, 223);

			@media only screen and (min-width: 768px) {
				border-bottom: none;
				width: 32%;
			}

			&:hover {
				img {
					transition: transform 0.2s;
					transform: scale(1.1);
				}

				.project-details {
					color: a.$white;
					transition: 0.2s;

					&::before {
						width: 100%;
					}
				}
			}

			img {
				height: 36rem;
				margin: 0 auto;
				width: 100%;
				object-fit: contain;
				transition: transform 0.2s;

				@media only screen and (min-width: 768px) {
					height: 50rem;
				}
			}

			h3 {
				margin: -0.5rem 0rem 2rem;
			}

			&-type {
				font-size: 1.4rem;
			}

			&-links,
			.case-links {
				a {
					color: a.$black;
					font-size: 1.6rem;
					font-family: a.$Grotesk;
				}

				.project-details {
					position: relative;
					transition: color 0.3s;

					&::before {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 0;
						height: 0.1rem;
						background-color: a.$white; /* Underline color */
						transition: width 0.3s; /* Transition underline width */
					}
				}
			}
		}
	}
}
